#LabHeader {
	text-align: center;
	font-size: 2rem;
	color: rgb(91, 230, 255);
	margin: 20px 0;
}

#Lab {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	width: 80vw;
	margin: 0 auto;
}

.dropdown {
	width: 100%;
	background-color: rgb(1, 1, 27);
	margin-bottom: 10px;
	border-radius: 10px;
	overflow: hidden;
}

.dropdown-header {
	font-size: 1.5rem;
	font-weight: 600;
	padding: 20px;
	background-color: #43434390;
	color: white;
	cursor: pointer;
	border-bottom: 1px solid #5be6ff;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.dropdown-icon {
	font-size: 1.5rem;
}

.dropdown-content {
	background-color: #1c1a22;
	padding: 0px 10px;
	padding-top: 18px;
	padding-bottom: 2px;
	border-radius: 0 0 10px 10px;
}

.LabTopicList {
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.LabTopic {
	padding: 15px;
	background-color: #333;
	color: #fff;
	font-family: Arial, sans-serif;
	border-radius: 8px;
	transition: background-color 0.3s ease;
}

.labCards {
	text-decoration: none;
}

.cardDiv {
	border: 1px solid #555;
	/* padding: 15px; */
	background-color: #444;
	/* border-radius: 8px; */
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	transition:
		transform 0.2s,
		box-shadow 0.3s;
	/* margin: 10px 0; */
	max-width: 100%;
}
/* 
.cardDiv:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 1px 1px #ffd700;
} */

.index {
	font-weight: bold;
	color: rgb(91, 230, 255);
}

.quest {
	line-height: 1.6;
	font-size: 1.15rem;
}

.dropdown-filter {
	width: 95vw;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-left: 5vw;
	align-items: baseline;
}

.custom-dropdown {
	position: relative;
	width: 200px;
	cursor: pointer;
	user-select: none;
	/* border-bottom: 1px solid #5be6ff; */
}

.custom-dropdown-selected {
	padding: 10px;
	display: flex;
	justify-content: space-between;
	background-color: #252531;
	border: none;
	border-radius: 5px 5px 0 0;
	border-bottom: 1px solid #5be6ff;
	font-size: 1rem;
	font-weight: 600;
	color: white;
}

.custom-dropdown-options {
	display: none;
	position: absolute;
	width: 100%;
	background-color: #193963;
	border: none;
	border-top: none;
	z-index: 100;
	border-radius: 0 0 5px 5px;
}

.custom-dropdown.open .custom-dropdown-options {
	display: block;
}

.custom-dropdown-option {
	color: white;
	padding: 10px;
	background-color: #252531;
}

.custom-dropdown-option:hover {
	font-weight: 700;
	background-color: #19396350;
}

.lt-btn-login {
	background-color: #252531;
	color: white;
	border: none;
	padding: 10px 40px;
	text-decoration: none;
	border-radius: 5px 5px 0px 0px;
	border-bottom: 1px solid #5be6ff;
	cursor: pointer;
	font-weight: 600;
	margin-top: 10px;
	text-transform: none;
	font-size: 1rem;
	width: 200px;
	transition: all 0.3s ease-out;
}

.lt-btn-login:hover {
	filter: brightness(150%);
}

@media (max-width: 480px) {
	.header h1 {
		font-size: 20px;
	}

	.dropdown-header {
		font-size: 14px;
		padding: 6px;
	}

	.dropdown-content p {
		font-size: 12px;
	}

	.dropdown-filter {
		flex-direction: column;
		align-items: center;
		gap: 1vh;
	}
}
