.theoryCards {
	text-decoration: none;
}

.cardDiv {
	border: 1px solid #555;
	background-color: rgb(91, 230, 255, 0.04);
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	transition:
		transform 0.2s,
		box-shadow 0.3s;
	max-width: 100%;
	width: 100%;
}

/* .cardDiv:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 1px 1px #ffd700;
} */

.text {
	color: rgb(247, 239, 239);
	margin: 10px 0;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	line-height: 1.4;
}

.index {
	font-weight: bold;
	color: rgb(91, 230, 255);
}
