#LabHeader {
	text-align: center;
	font-size: 2rem;
	color: rgb(91, 230, 255);
	margin: 20px 0;
}

#Lab {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: center;
	padding: 20px;
	flex-direction: column;
}

.LabItem {
	text-align: center;
	border: 1px solid #555;
	padding: 20px;
	background-color: #434343;
	border-radius: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	transition: transform 0.2s;
	margin: 10px;
	flex: 1;
	width: 80vw;
	text-decoration: none;
	color: #fff;
}

.LabItem:hover {
	transform: translateY(-5px);
}

.LabItem a {
	color: #fff;
	text-decoration: none;
	font-size: 1.25rem;
	font-weight: 600;
}

.LabItem a:hover {
	color: #5be6ff;
}

/* Scoped link styling only for this component */
.classOfLinks {
	text-decoration: none;
	font-size: 1.25rem;
	font-weight: 600;
}
