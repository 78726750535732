.selcard {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
}
.selcardel {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0.5rem solid white;
}
.people {
	text-align: center;
	color: white;
	font-size: 1.5rem;
	margin: 1vw;
}
.people > p {
	text-align: center;
}
.tab {
	color: white;
	padding: 1vw;
}
.tab > th,
td {
	padding: 1vw 1vw 1vw 1vw;
	text-align: center;
}

th {
	font-size: 2rem;
	font-weight: 700;
	color: #56c3d5;
}

td {
	font-size: 1.5rem;
}

.teams::before {
	content: '';
	position: fixed;
	top: 20%;
	left: 20%;
	width: 60%;
	height: 60%;
	/* background: url("../public/KIK_logo-removebg.png") no-repeat center
    center/contain; */
	opacity: 0.3;
	z-index: -1;
}

/* <<<<<..........................SCroll Bar Edit..............................>>>>>>> */

::-webkit-scrollbar {
	-webkit-appearance: none;
	/* style the scrollbar */
}

::-webkit-scrollbar:vertical {
	width: 5px;
	/* width of the scrollbar */
}

::-webkit-scrollbar:horizontal {
	height: 5px;
	/* height of the scrollbar */
}

::-webkit-scrollbar-track {
	background: #ffffff00;
	/* color of the track */
}

::-webkit-scrollbar-thumb {
	background: #56c3d5;
	/* color of the scroll thumb */
	border-radius: 6px;
	/* roundness of the scroll thumb */
	border: 0.5px solid #040000;
	/* border of the scroll thumb */
}

/* Width */
.scrollbar {
	scrollbar-width: thin; /* "auto" or "thin" */
	scrollbar-color: #56c3d5 transparent; /* thumb and track color */
}

/* Track */
.scrollbar-track {
	background: #ffffff00; /* color of the track */
}

/* Thumb */
.scrollbar-thumb {
	background-color: #56c3d5; /* color of the scroll thumb */
	border-radius: 6px; /* roundness of the scroll thumb */
	border: 0.5px solid #040000; /* border of the scroll thumb */
}

@media screen and (max-width: 800px) {
	.teams::before {
		top: 15%;
		left: 15%;
		width: 70%;
		height: 70%;
	}
}
