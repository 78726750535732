.login-modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.login-modal {
	background: #212121;
	color: #f1f1f1;
	border-radius: 8px;
	padding: 20px;
	width: 400px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.login-modal h2 {
	padding-bottom: 10px;
}

.close-button {
	background: none;
	border: none;
	color: #f1f1f1;
	font-size: 1.5rem;
	cursor: pointer;
	position: absolute;
	top: 10px;
	right: 10px;
}

.form-group {
	margin-bottom: 15px;
}

.form-group label {
	display: block;
	margin-bottom: 5px;
	font-size: 0.9rem;
}

.form-group input {
	width: 100%;
	padding: 10px;
	border: 1px solid #444;
	border-radius: 4px;
	background: #333;
	color: #f1f1f1;
	font-size: 1rem;
}

.login-button {
	width: 100%;
	padding: 10px;
	background: #0074d9;
	color: #f1f1f1;
	border: none;
	border-radius: 4px;
	font-size: 1rem;
	cursor: pointer;
	transition: background 0.3s ease;
}

.login-button:hover {
	background: #0074f9;
}
