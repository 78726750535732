@import url('https://fonts.googleapis.com/css2?family=Fredericka+the+Great&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Slab:wght@100..900&display=swap');

body {
	display: block;
	margin: 0;
	padding: 0;
}

.question {
	font-family: 'Poppins', sans-serif;
	font-weight: 900;
	font-style: normal;
	background-color: #222;
	color: #fff;
	padding: 20px;
	margin: 20px 1rem;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	position: relative;
	font-size: 1rem;
}

.pretext {
	overflow-x: auto;
}

.copyBtn {
	font-size: 1rem;
	font-weight: 400;
	background-color: rgb(48, 40, 40);
	color: white;
	padding: 0.3rem 1rem 0.3rem 0.5rem;
	cursor: pointer;
}

.clip {
	padding: 0.3rem 0.5rem 0.3rem 0rem;
}

.copy {
	margin: 0;
	background-color: #282a36;
}

#toggleBtn {
	background-color: #00a1d9;
	color: #fff;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	font-size: 1rem;
	cursor: pointer;
	margin: 10px;
}

#toggleBtn:hover {
	background-color: #007dab;
}

#toggleBtn.hide-solution {
	background-color: #00a1d9;
}

#toggleBtn.hide-solution:hover {
	background-color: #007dab;
}

.solution {
	position: relative;
	display: flex;
	flex-direction: column;
	margin: 20px;
}

.onlineEditor {
	background-color: #00a1d9;
	color: #fff;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	font-size: 1rem;
	cursor: pointer;
	margin: 10px;
}

.onlineEditor:hover {
	background-color: #007dab;
}

.onlineEditor a {
	color: #fff;
	text-decoration: none;
}

.react-syntax-highlighter-code {
	background-color: #333;
	padding: 1.9rem;
	margin: 0;
	border-radius: 10px;
}
