@import url('https://fonts.googleapis.com/css?family=Montserrat:400,400i,700,700i');

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.landing-page {
	background-color: rgb(1, 1, 27);
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	overflow: hidden;
}

.logo {
	width: 20px;
	height: 20px;
}

.content {
	display: flex;
	justify-content: space-between;
	padding: 20px;
	padding-left: 40px;
}

.content .right {
	text-align: center;
	font-size: large;
	padding: 30px;
}

h1 {
	font-size: 65px;
	font-weight: 600;
	color: white;
	text-align: left;
}

h1 span {
	font-size: 80px;
	font-weight: 700;
	background: linear-gradient(
		to bottom right,
		rgb(17, 227, 251),
		rgb(91, 230, 255),
		rgb(181, 246, 253),
		rgb(17, 227, 251)
	);
	-webkit-background-clip: text;
	color: transparent;
	background-clip: text;
	text-align: center;
}

p {
	color: white;
	text-align: left;
}

.wel {
	font-size: 65px;
	font-weight: 600;
	color: white;
	text-align: left;
}

.vision {
	color: rgb(151, 152, 152);
	font-family: 'Poppins', sans-serif;
	font-size: 18px;
	font-weight: 400;
	line-height: 30.8px;
	padding-top: 40px;
	padding-bottom: 40px;
	text-align: left;
}

.vision-depth {
	font-family: 'Poppins', sans-serif;
	font-size: 18px;
	font-weight: 400;
	line-height: 30.8px;
}

.left {
	position: relative;
	bottom: 30px;
	width: 30%;
}

.content .left {
	height: 70%;
	width: 5000px;
	margin-top: 80px;
}

.bottom-bar .text {
	color: white;
	font-size: x-large;
}

.bottom-bar {
	display: flex;
	/* align-items: center; */
	justify-content: space-between;
	padding-left: 60px;
	padding-right: 60px;
	padding-bottom: 60px;
}

.bottom-bar .text {
	font-size: xx-large;
	font-weight: 600;
}

.bottom-bar .text span {
	font-size: x-large;
	background: linear-gradient(
		to bottom right,
		rgb(17, 227, 251),
		rgb(91, 230, 255),
		rgb(181, 246, 253),
		rgb(17, 227, 251)
	);
	-webkit-background-clip: text;
	color: transparent;
	background-clip: text;
}

.content .right h2 {
	color: rgb(17, 227, 251);
	font-size: 36px;
	margin-top: 40px;
}

.communities {
	border: 1px solid #fff;
	border-radius: 1.5vw;
	box-shadow: 0 0 20px #fff;
	margin: 2vw auto 5vw;
	padding: 2vw 1.5vw;
	width: 80vw;
	color: white;
}

/* App.css */
#loadingContainer {
	position: fixed;
	top: 0;
	left: 0;
	width: 25%;
	height: 25%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
}

.square-image {
	top: 0;
	position: fixed;
	height: 99%;
	width: auto;
}

/* pds-page-poster */

.pds-page-poster-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 50px;
	text-decoration: none;
}

.pds-page-poster {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 85vw;
	background-color: rgba(70, 69, 69, 0.2);
	border-radius: 30px;
	text-decoration: none;
}

.pds-page-poster-left {
	width: 45vw;
	text-decoration: none;
}

.pds-page-poster-left h1 {
	font-size: 3vw;
	padding: 10px 0;
	background: linear-gradient(
		to bottom right,
		rgb(17, 227, 251),
		rgb(91, 230, 255),
		rgb(181, 246, 253),
		rgb(17, 227, 251)
	);
	/* -webkit-background-clip: text; */
	color: transparent;
	background-clip: text;
	text-decoration: none;
	font-family: 'Montserrat';
}

.pds-page-poster-left h2 {
	font-size: 1.5vw;
	padding: 10px 0;
	color: aliceblue;
	font-family: 'Montserrat';
}

.pds-page-poster-right {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 25px;
	margin-left: 10px;
}

.pds-page-poster-right img {
	width: 35vw;
}

@media screen and (max-width: 768px) {
	.pds-page-poster {
		flex-direction: column;
		width: 70vw;
		padding: 30px 10px;
		margin-top: 20px;
	}

	.pds-page-poster-right {
		margin: 20px;
	}

	.pds-page-poster-right img {
		width: 60vw;
	}

	.pds-page-poster-left {
		width: 70vw;
		padding: 0 50px;
	}

	.pds-page-poster-left h1 {
		font-size: 4vw;
		padding: 10px 0;
		text-align: center;
	}

	.pds-page-poster-left h2 {
		padding: 10px 0;
		font-size: 3vw;
		text-align: center;
	}
}

@media screen and (max-width: 768px) {
	.bottom-bar {
		display: flex;
		flex-direction: column;
		align-items: left;
		text-align: left;
		justify-content: space-between;
		padding: 2rem;
	}

	.square-image {
		margin-top: 100px;
		position: fixed;
		height: auto;
		width: 100%;
	}
}

@media screen and (max-width: 1000px) {
	.content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 20px;
		padding-left: 40px;
	}

	.content .left {
		height: auto;
		width: 100%;
	}

	.content {
		display: flex;
		justify-content: space-between;
		padding: 0px;
	}
}

@media screen and (max-width: 500px) {
	.wel {
		font-size: 50px;
	}

	h1 span {
		font-size: 60px;
	}

	.content .right {
		padding: 1.5rem;
	}

	.content .left img {
		height: auto;
		width: 120%;
	}
}

@media screen and (max-width: 380px) {
	.wel {
		font-size: 40px;
	}

	h1 span {
		font-size: 50px;
	}

	.bottom-bar .text {
		font-size: x-large;
		font-weight: 600;
	}
}
