@import url('https://fonts.googleapis.com/css?family=Montserrat:400,400i,700,700i');

/* @keyframes scaleIn {
  from {
    transform: scale(0.5);
  }

  to {
    transform: scale(1);
  }
}

.eventImage img {
  animation: scaleIn 0.5s ease-out;
  transition: transform 0.3s ease;
  max-height: 55vh;
} */

/* .eventImage img:hover {
  transform: scale(1.1) rotate(3deg);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  transition: transform 0.5s ease, box-shadow 0.5s ease;
} */

#recentHeader {
	margin: 2rem 0rem 1rem 0rem;
	font-family: 'Poppins', sans-serif;
	text-align: center;
	color: transparent;
	background: linear-gradient(
		to bottom right,
		rgb(17, 227, 251),
		rgb(91, 230, 255),
		rgb(181, 246, 253),
		rgb(17, 227, 251)
	);
	background-clip: text;
	-webkit-background-clip: text;
	font-size: 48px;
}

/* #event1,
#event3 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin: 1rem 1rem;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.5s ease;
  background: linear-gradient(-168.39deg,#ffffff -278.56%,#24248b -78.47%, #1d1b66 5.67%, #11101d 91.61%);
  background: transparent;
}

#event2 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap-reverse;
  margin: 1rem 1rem;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.5s ease;
  background: linear-gradient(-168.39deg,#ffffff -278.56%,#24248b -78.47%, #1d1b66 5.67%, #11101d 91.61%);
  background: transparent;
}

.eventImage {
  width: 40%;
  padding: 1rem;
  text-align: left; 
  color: #fff;
}

.eventDescription {
  width: 60%;
  padding: 1rem;
  text-align: left;
  color: #fff;
}

.eventImage img {
  width: 400px;
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

#imgAi {
  width: 80%;
}

.eventDescription {
  width: 45%;
  padding: 1rem;
  color: #fff;
  color: #ffffffb3;
  opacity: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 30.8px;
  filter: blur(5px);
  transition: all 1s;
}

.eImages {
  opacity: 0;
  filter: blur(5px);
  transition: all 5s;
  transform: translateY(100%);
}

.show {
  opacity: 1;
  filter: blur(0px);
  transform: translateY(0);
}

.textAni {
  opacity: 1;
  filter: blur(0px);
}

.workHeader {
  font-family: 'Poppins', sans-serif;
  font-size: 3.5rem;
  line-height: 76.8px;
  margin-bottom: 1.5rem;
  color: rgb(17, 227, 251);
  text-align: center;
}

@media screen and (max-width: 1000px) {
  .recentEvent {
    display: block;
  }

  .eventImage {
    width: 320px;
    padding: 0px;
    margin-top: 2rem;
  }

  .eventDescription {
    width: 90%;
    padding: 0rem;
    margin: 1rem 0rem;
  }

  #event1,
  #event2,
  #event3 {
    margin: 0rem;
    padding: 0rem;
  }

  .workHeader {
    line-height: 66px;
    font-size: 45px;
  }

  #recentEvents {
    padding: 0rem;
  }

  #recentHeader {
    margin: 2rem 0.5rem 1rem 0.5rem;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    color: #fff;
    font-size: 2em;
    font-size: 40px;

  }
} */

.work-wrapper {
	position: relative;
}

.field-cards {
	margin-bottom: 20vw;
}

.field-card {
	padding: 0;
	background-color: rgba(54, 54, 54, 0.4);
	border-radius: 2.5vw;
	height: 40vw;
	transition: all 1s cubic-bezier(0.63, 0.15, 0.03, 1.12);
	margin-top: 20px;
}

.field-card img {
	width: 5vw;
	height: 50vh;
}

.field-heading {
	margin-top: 1.5vw;
	margin-bottom: 0.5vw;
	text-align: center;
	font-family: 'Montserrat';
	font-size: xx-large;
	background: linear-gradient(
		to bottom right,
		rgb(17, 227, 251),
		rgb(91, 230, 255),
		rgb(181, 246, 253),
		rgb(17, 227, 251)
	);
	-webkit-background-clip: text;
	color: transparent;
	background-clip: text;
}

.field-description {
	padding: 10px 20px;
	text-align: center;
	font-family: 'Montserrat';
}

.separator-line {
	width: 80%;
	height: 1px;
	/* thin line */
	background: linear-gradient(
		to right,
		transparent,
		rgba(0, 0, 0, 0.5),
		transparent
	);
	/* tapering effect */
	margin: 20px auto;
	/* centers the line and adds some spacing */
}

@media (max-width: 768px) {
	.separator-line {
		width: 80%;
		height: 1px;
		background: linear-gradient(
			to right,
			transparent,
			rgba(255, 255, 255, 0.5),
			transparent
		);
		margin: 20px auto;
	}
}

@media only screen and (min-width: 769px) {
	.field-card {
		width: 20vw;
		position: absolute;
		top: 70%;
		left: 50%;
	}

	.field-card img {
		width: 20vw;
	}

	.field-heading {
		font-size: 2vw;
	}

	.field-cards {
		position: relative;
		height: 30vw;
	}

	.card1 {
		z-index: 1;
		transform: translate(-50%, -50%) rotateZ(5deg);
		transition-delay: 0.1s;
	}

	.card2 {
		z-index: 2;
		transform: translate(-50%, -50%) rotateZ(-7deg);
		transition-delay: 0.05s;
	}

	.card3 {
		z-index: 3;
		transform: translate(-50%, -50%) rotateZ(-2deg);
	}

	.field-description {
		width: 19vw;
		margin: 0 auto;
		padding-bottom: 1vw;
		font-size: 1.25vw;
	}
}

@media only screen and (max-width: 768px) {
	#recentHeader {
		font-size: 10vw;
	}

	.field-card {
		width: 70vw;
		height: 130vw;
		position: relative;
		left: 0;
		top: 0;
		transform: translate(0, 0);
		margin-bottom: 20px;
	}

	.field-card img {
		height: 40vh;
	}

	.field-heading {
		font-size: 7vw;
		margin-top: 5vw;
	}

	.field-cards {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		max-height: fit-content;
		margin-bottom: 5vh;
	}

	.field-description {
		width: 100%;
		padding: 10px 30px;
		font-size: 3.8vw;
	}
}
