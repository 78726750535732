@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;500;600&display=swap');

.heading {
	color: white;
	font-family: 'Poppins';
	font-size: 48px;
	margin-left: 5px;
	margin-bottom: 1.5rem;
	/* margin-bottom: 1rem; */
	margin-top: 2rem;
	background: radial-gradient(
		64.18% 64.18% at 71.16% 35.69%,
		#def9fa 0.89%,
		#bef3f5 17.23%,
		#9dedf0 42.04%,
		#7de7eb 55.12%,
		#5ce1e6 71.54%,
		#33bbcf 100%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	font-weight: bold;
	font-family: 'Kanit', sans-serif;
}

.hackathon .hackathon img {
	width: 80%;
	border-radius: 8px;
	max-height: 500px;
	margin: 0 auto;
}

.events {
	display: flex;
	flex-direction: column;
	width: 95%;
	margin: 0 auto;
}

.event {
	display: flex;
	gap: 2rem;
	color: white;
	margin-top: 0;
	padding-bottom: 1rem;
	border-bottom: 2px solid white;
	/* margin-top: 1rem; */
}

.event_heading {
	font-size: 30px;
	margin: 0.5rem 0 1rem 0;
}

.event1 {
	margin-top: 1rem;
}

.event img {
	width: 45%;
	max-height: 350px;
	border-radius: 8px;
}

.event_description {
	line-height: 30.8px;
	font-weight: 400;
	color: #ffffffb3;
	font-size: 16px;
}

.event1,
.event3 .event_description {
	margin-right: 5rem;
}

.event2 {
	flex-direction: row-reverse;
	padding-right: 3rem;
}

.sponsors {
	display: flex;
	gap: 20px;
	margin-top: 1rem;
	align-items: center;
}

/* .sponsor_name {
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins';
} */

/* .twoSponContainer{
    display: flex;
    flex-wrap: wrap;
    margin: 1rem ;
}
.twoSpon{
    margin-top: 1rem;
    margin-right: 1rem;
} */
.apply_btns {
	display: flex;
	justify-content: space-between;
	width: 80%;
	margin: 0.5rem auto;
	padding: 0 2%;
	gap: 10px;
}

.applybtn {
	border-radius: 5px;
	padding: 10px 25px;
	background: radial-gradient(
		64.18% 64.18% at 71.16% 35.69%,
		#def9fa 0.89%,
		#bef3f5 17.23%,
		#9dedf0 42.04%,
		#7de7eb 55.12%,
		#5ce1e6 71.54%,
		#33bbcf 100%
	);
	/* margin: 1rem 12.5%; */
}

/* .register {
    font-size: 1.5rem;
    font-weight: 600;
    font-family: Roboto;
    color: black;
} */

.applybtn span,
.register {
	color: black;
	/* font-size: 25px; */
	font-family: 'Roboto Mono', monospace;
	font-weight: 600;
}

.sponsor_heading {
	font-size: 20px;
	/* font-weight: 400; */
	color: white;
	font-family: 'Poppins';
}

.sponsor_list {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}

.sponsor_item {
	display: flex;
	align-items: center;
}

.sponsor_logo {
	width: 35px !important;
	height: 35px !important;
	border-radius: 50%;
	object-fit: cover;
}

.sponsor_name {
	margin: 0 10px;
}

@media screen and (max-width: 1000px) {
	.event {
		flex-direction: column;
		gap: 1rem;
	}

	.event img {
		width: 88%;
		margin: 0 auto;
	}

	.event .event_heading {
		font-size: 25px;
	}
	.event .event_description {
		margin: 0;
		font-size: 14px;
	}

	.event_content {
		padding: 0 0.5rem;
	}
	.heading {
		font-size: 35px;
		margin-left: 1rem;
	}
	.sponsors {
		margin-top: 0.5rem;
	}

	.event2 {
		padding-right: 0;
	}

	.event {
		margin: 0;
	}
}

@media screen and (max-width: 900px) {
	.applybtn {
		font-size: 15px;
		padding: 5px 10px;
	}
}

/* @media screen and (min-width: 1200px) {
    .hackathon img {
        max-height: none;
    }
} */
